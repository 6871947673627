.App {
  text-align: center;
  background-color: rgb(241, 241, 236);
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  .App {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 1000px) {
  .App {
    font-size: 22px;
  }
}

.font-link {
  display: block;
  font-family: "Libre Baskerville", serif;
  text-align: center;
  align-content: center;
  margin-left: auto;
  margin-right:auto;
}

.Nav_Bar {
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgb(155, 212, 191)
  );
  position: relative;
  margin: auto;
  padding: 0.5rem;
  max-width: 750px;
  align-content: center;
  text-align: center;  
}

.User_Icon {
  text-align: center;
}

.user-link {
  text-decoration: none;
  font-style: italic;
  font-weight: 600;
}

.user-link:hover {
  font-weight: bold;
  text-decoration: underline;
}

.logout {
  font-style: italic;
  font-weight: 600;
  position: absolute;
  top: 0px;
  right: 1em;
  cursor: pointer;
}

.logout:hover {
  font-weight: bold;
  text-decoration: underline;
}

.Nav-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
  align-items: center;
}

.Nav_Link {
  background-color: rgba(201, 76, 76, 0);
  min-width: 5em;
  margin: auto;
  color: black;
  padding-top: 14px;
  padding-bottom: 14px; 
  font-size: 90%;
  cursor: pointer;
  border-radius: 5px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.Nav_Link:hover {
  background-color: rgb(144, 197, 178);
}

.Avatar_Image {
  width: 70px;
  border-radius: 50%;
}

.Nav_Articles {
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgb(227, 203, 250)
  );
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  max-width: 40%;
}

.Articles_sort {
  text-align: center;
  font-size: 1em;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 1em;
}

.Radio-order {
  margin-top: 1em;
  display: flex;
  max-width: 75%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.select-by {
  margin: 1em;
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.75em;
}

.Topic-buttons {
  margin: auto;
  max-width: 750px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1em;
}

.Topic-button {
  background-color: rgb(241, 241, 236);
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgb(227, 203, 250)
  );
  min-width: 5em;
  color: black;
  padding-top: 14px;
  padding-bottom: 14px; 
  font-size: 90%;
  cursor: pointer;
  border-radius: 5px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.Topic-button:hover {
  background-color: rgb(227, 203, 250);
}

.user-msg {
  display: block;
  text-align: center;
  align-content: center;
}

.article-card {
  border-style: dotted;
  border-color: rgb(124, 124, 124);
  background-color: beige;
  margin-top: 2rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1%;
  padding-right: 1%;
  text-align: center;
  max-width: 850px;
}

.Article-heading {
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgb(227, 203, 250)
  );
  max-width: 75%;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  text-align: center;
}

.Article-body {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  padding: auto;
  display: block;
  align-content: center;
  text-align: center;
}

.Article-text {
  text-align: left;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.TextareaAutosize {
  font-size: large;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  position: relative;
  display: block;
  overflow: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 90%;
  max-width: 700px;
  background-color: rgba(211, 211, 209, 0.72);
}

.topics-block {
  display: block;
  overflow: auto;
  justify-content: space-between;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
}

ul {
  list-style-type: none;
  background-color: rgb(227, 203, 250);
  margin-top: 1em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem;
  max-width: 750px;
}

.article-vote {
  display: flex;
  justify-content: center;
  align-items: center;
}

.comment-card {
  border-style: dotted;
  border-color: rgb(124, 124, 124);
  background-color: beige;
  max-width: 850px;
  padding: 0.25em;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.comment-body {
  text-align: justify;
  white-space: pre-line;
}

.comment-toggle {
  margin-top: 1em;
}

.votedel-block {
  display:flex;
  text-align: center;
  align-items: center;
}

.comment-vote {
  display:flex;
  flex: 1;
  max-width: 7em;
  margin: auto;
  align-items: center;
}

.com-delete {
  flex:1;
  max-width: 7em;
  margin-right:auto;
}

.IconButton:hover {
  color: #F96574;
  font-size:1.5em;
}

button {
  background-color: rgb(211, 209, 209);
  font-size: 90%;
  cursor: pointer;
  border-radius: 5px;
  transition-duration: 0.4s;  
}
 
button:hover {
  background-color: rgb(224, 224, 224);
}

.Error-msg {
  display: block;
  text-align: center;
  align-content: center;
}

.profile-load {
  text-align: center;
}

.users-head {
  text-align: center;
}

.users-block {
  display: grid;
  row-gap: 1em;
  column-gap: 1em;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin: auto;
  margin-top: 0.5rem;
  justify-items: center;
  max-width: 1000px;
  padding-bottom: 1em;
}
  
.user-card {
  text-align: center;
  height: 100%;
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  background-color: rgb(227, 203, 250);
  padding-bottom: 0.2em;
  padding-top: 0.2em;
}

.login-user {
  cursor: pointer;
  font-size: 1.8vh;
  text-decoration: underline;
}

@media screen and (min-width: 550px) {
  .login-user {
    font-size: 25px;
  }
}

.login-user:hover {
  font-weight: bold;
}

.user-avatar {
  height: 5em;
  max-width: 100%;
  min-width: 10%;
} 

#back-link {
  cursor: pointer;
  text-decoration: underline;
}

Footer {
  background-image: linear-gradient(
    to left,
    rgba(255, 0, 0, 0),
    rgb(155, 212, 191)
  );
  border-style: ridge;
  margin: 0.5em;
  margin-top: 10em;
  font-size: small;
  text-align: left;
  padding-left: 1em;
}

CircularProgress {
  margin: 1em;
}

.load-block {
  text-align: center;
}

a:hover {
  font-weight: bolder;
}